import { AuthUserType } from 'src/auth/AuthProvider';
import { MemberType } from './memberInfo';

export type Authorization = {
  accountId: number;
  accountLocked: boolean;
  accountLockedMinutes: number;
  accountPermissions: string;
  bidVisitExceededUrl?: string;
  email: string;
  firstName: string;
  fullName: string;
  hasError: boolean;
  isBidVisitExceeded?: boolean;
  lastName: string;
  mainContact: boolean;
  memberBlackListed: boolean;
  memberCreatedDate: string;
  memberId: number;
  membershipLevels: string;
  memberStatus: string;
  memberType: MemberType | ''; // Darren's opinion here is that we should avoid the pattern of initializing with empty data. If we don't have an authorization object, it's better for it to be `null` so that our typing does not need to have exceptions for '', and we don't end up assuming we have something loaded that we don't.
  migrated: boolean;
  opsId: number;
  primaryMemberId: number;
  themetokenName: string; // SUGGESTION: Darren think this should just be 'theme'
  token: string;
  userName: string;
  userType: AuthUserType; // FIXME: This should be renamed in our new Recoil authorization to avoid confusion.
  validatedResult: { status: boolean; firstName: string; lastName: string };
};

export type GetAuthTokenRequest = {
  userName: string;
  password: string;
};

export type LegacyPayload = GetAuthTokenRequest & {
  pagefor?: string;
  orderBidPackage?: any;
  pageBid?: number;
};

export type LegacyLoginRequest = {
  auth: Authorization;
  legacyPayload: LegacyPayload;
};

export type GetAuthTokenResponse = {
  isBidVisitExceeded?: boolean;
  token: string;
  url?: string;
};

export enum Permission {
  Ops = '1',
  EditMemberInfo = '2',
  ManageAccounts = '3',
  ManageBids = '4',
  CreateQuotes = '5',
  ManagePrograms = '6',
  AwardQuotes = '7',
  Fulfillment = '8',
  SalesPerson = '9',
  SalesManager = '10',
  ManageReports = '11',
  DeDuplicateMembers = '12',
  GiveCredit = '13',
  RespondToBids = '14',
  RespondToQuotes = '15',
}

export enum MembershipLevel {
  BidPoster = '1',
  BidResponder = '2',
  QuotePoster = '3',
  QuoteResponder = '4',
  EBiddingResponder = '5',
}
