import React, { memo, useState } from 'react';
import { Table } from 'reactstrap';
import { checkPaginationCallFn, deprecatedGetDateTimeZoneConverted } from '../../../utils/helpers';
import NoResult from '../../customcontrols/noresult';
import { MemberType, pageSize } from '../../../utils/constants';
import { loadingMsg } from '../../../utils/texts';
import { Paging } from '../../customcontrols';
import history from '../../../utils/history';
import { activitiesTypes, memberinfoTypes } from '../../../types';
import { useAuthContext } from 'src/auth/AuthProvider';

interface propsTypes {
  activitieslist?: any[];
  activities: activitiesTypes;
  pagefor: string;
  total: number;
  getActivitiesSearch: any;
  memberinfo: memberinfoTypes;
}

function ActivitiesSearchresult(props: propsTypes) {
  const {
    activitieslist = [],
    activities,
    pagefor,
    total,
    getActivitiesSearch,
    memberinfo,
  } = props;
  const { auth } = useAuthContext();
  const {
    search_internalLoader,
    searchSelect,
    search,
    notificationStatus,
    bitWatchStatus,
    planholderStatus,
    ebidproposalStatus,
    quotesStatus,
  } = activities;
  const { tzfn, tzn } = memberinfo;
  // Logic for displaying pagination
  const [currentPages, setCurrentPages] = useState(1);
  const listPerPages = pageSize.default;
  const indexOfLastLists = currentPages * listPerPages;
  const indexOfFirstLists = indexOfLastLists - listPerPages;

  const onPagingClick = (value: number) => {
    if (value !== currentPages) {
      const final = {
        page: Math.ceil(value / listPerPages),
        size: 100,
        pagination: true,
        pagefor,
        search,
        searchSelect,
        notificationStatus,
        bitWatchStatus,
        planholderStatus,
        ebidproposalStatus,
        quotesStatus,
        type: '',
      };
      switch (pagefor) {
        case 'notificationsbid':
          final.type = 'notification';
          break;
        case 'notificationsaccount':
          final.type = 'notification';
          break;
        case 'bidwatch':
          final.type = 'watch';
          break;
        case 'planholders':
          final.type = 'planholder';
          break;
        case 'ebidresponse':
          final.type = 'bidder';
          break;
        case 'quotes':
          final.type = 'quote';
          break;
        default:
      }
      if (checkPaginationCallFn(value, listPerPages, activitieslist, total)) {
        getActivitiesSearch(final);
      }
      setCurrentPages(Number(value));
    }
  };

  const GoToPage = (item: { type: string; id: string | number }) => {
    const user = auth?.memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers';
    const page = item.type === 'Bid' ? 'bids' : item.type === 'Quote' ? 'quotes' : null;
    if (page)
      history.push(
        `/${user}/${page}/${item.id}/${
          auth?.memberType === MemberType.AgencyBuyer || item.type === 'Bid' ? 'details' : ''
        }`,
      );
  };

  const finallist = activitieslist.slice(indexOfFirstLists, indexOfLastLists) || [];

  return (
    <>
      <Table className='tableHData tableFixed'>
        <thead>
          <tr>
            {pagefor === 'notificationsbid' ? (
              <>
                <th /*/*TBWIDTH width={activitieslist.length > 0 ? null : '30%'}*/>Title</th>
                {auth?.memberType === MemberType.AgencyBuyer ? null : (
                  <th /*/*TBWIDTH width='20%'*/>Buyer Name</th>
                )}
              </>
            ) : pagefor === 'notificationsaccount' ? (
              <>
                <th /*TBWIDTH width='25%'*/>Title</th>
              </>
            ) : pagefor === 'quotes' ? (
              <>
                <th /*TBWIDTH width={activitieslist.length > 0 ? null : '20%'}*/>Quote Name</th>
              </>
            ) : (
              <>
                <th /*TBWIDTH width={activitieslist.length > 0 ? null : '20%'}*/>Bid Name</th>
              </>
            )}
            {pagefor === 'notificationsbid' || pagefor === 'notificationsaccount' ? null : (
              <>
                {auth?.memberType === MemberType.AgencyBuyer ? (
                  <>{pagefor === 'quotes' ? null : <th /*TBWIDTH width='20%'*/>Supplier</th>}</>
                ) : (
                  <th /*TBWIDTH width='20%'*/>Agency</th>
                )}
              </>
            )}
            {pagefor === 'notificationsbid' ||
            pagefor === 'notificationsaccount' ||
            pagefor === 'bidwatch' ? null : (
              <th /*TBWIDTH width='20%'*/>ID</th>
            )}
            {pagefor === 'notificationsbid' ? (
              <>
                <th /*TBWIDTH width='30%'*/>ID</th>
                <th /*TBWIDTH width='25%'*/>Message</th>
              </>
            ) : pagefor === 'notificationsaccount' ? (
              <th /*TBWIDTH width={activitieslist.length > 0 ? null : '50%'}*/>Message</th>
            ) : (
              <th /*TBWIDTH width='25%'*/>Activity</th>
            )}
            <th /*TBWIDTH width='225px'*/>Date / Time </th>
          </tr>
        </thead>
        <tbody>
          {activitieslist && activitieslist.length > 0 ? (
            <>
              {finallist.length > 0 ? (
                <>
                  {finallist.map((item, index) => (
                    <tr key={index}>
                      <td className='text-truncate'>
                        {item.id && (item.type === 'Bid' || item.type === 'Quote') ? (
                          <span
                            title={item.title}
                            className='staticLink'
                            onClick={() => GoToPage(item)}
                          >
                            {item.title}
                          </span>
                        ) : (
                          item.title
                        )}
                      </td>
                      {pagefor === 'notificationsaccount' ? null : (
                        <>
                          {auth?.memberType === MemberType.AgencyBuyer ? (
                            <>
                              {pagefor === 'notificationsbid' || pagefor === 'quotes' ? null : (
                                <td>{item.supplierName}</td>
                              )}{' '}
                            </>
                          ) : (
                            <td>{item.buyerName}</td>
                          )}
                        </>
                      )}
                      {pagefor === 'bidwatch' || pagefor === 'notificationsaccount' ? null : (
                        <td>{item.identifier}</td>
                      )}
                      <td>
                        {item.description}{' '}
                        {auth?.memberType === MemberType.AgencyBuyer
                          ? deprecatedGetDateTimeZoneConverted(item.actionOn, tzfn)
                          : deprecatedGetDateTimeZoneConverted(
                              item.actionOn,
                              'Eastern Time (US & Canada)',
                            )}
                      </td>
                      <td>
                        {auth?.memberType === MemberType.AgencyBuyer ? (
                          <>
                            {deprecatedGetDateTimeZoneConverted(item.dateOfAction, tzfn)} {tzn}
                          </>
                        ) : (
                          <>
                            {deprecatedGetDateTimeZoneConverted(
                              item.dateOfAction,
                              'Eastern Time (US & Canada)',
                            )}{' '}
                            Eastern
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={10}>
                    <NoResult message={loadingMsg} />
                  </td>
                </tr>
              )}
            </>
          ) : (
            <tr>
              <td colSpan={10}>
                <NoResult
                  message={search_internalLoader ? loadingMsg : 'No Search Result Available'}
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <footer className='row'>
        <div className='col-12 '>
          <Paging
            directpage={false}
            totalRecords={total}
            currentPage={currentPages}
            onPagingClick={onPagingClick}
            limitmsg={false}
            pageLimit={listPerPages}
          />
        </div>
      </footer>
    </>
  );
}

export default memo(ActivitiesSearchresult);
