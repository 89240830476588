import procure from '../../../assets/images/eunaicons/Solutions_Procure.svg';
import budget from '../../../assets/images/eunaicons/Solutions_Budget-edited.svg';
import grants from '../../../assets/images/eunaicons/Solutions_Grants.svg';
import permits from '../../../assets/images/eunaicons/Solutions_Permits.svg';
import admin from '../../../assets/images/eunaicons/Solutions_Education.svg';
import payment from '../../../assets/images/eunaicons/Solutions_Payments.svg';

export const showEunacards = true; //will remove EUNA ad on agency Dashboard

export const eunaDashBoardIcons = {
  procure: procure,
  Budget: budget,
  Grants: grants,
  permits: permits,
  Admin: admin,
  Payments: payment,
} as const;
