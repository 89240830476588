import { Field, Formik } from 'formik';
import { memo, useCallback, useEffect } from 'react';

import renderCheckBox from '../../../../../common/controls/inputs/checkbox';
import renderToggleBtn from '../../../../../common/controls/inputs/togglebtn';
import UseraccountControl from '../../../../../common/breadcrumb/accountinfo/useraccount';

import { Buttons } from '../../../../../customcontrols';
import { MemberType } from '../../../../../../utils/constants';
import { renderDeprecatedTextField } from '../../../../../common/controls/inputs/text';
import { validate } from './validate';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { useAuthContext } from 'src/auth/AuthProvider';

interface PropsTypes {
  GoBackToPage: () => void;
  permissions: Array<{
    permissionId: number;
    permissionName: string;
  }>;
  initialValues: {
    accountId: number;
    mainContact: boolean;
    active: boolean;
    permissionCheck: any;
  };
  history?: any;
  onSubmit?: any;
  mt?: string | undefined;
  submitting?: boolean;
  getUserAccounts?: any;
  mi?: number | undefined;
  auth?: {
    mc: boolean;
    us: string | number;
    opi: any;
  };
  unblockClick: any;
  userAccounts: any;
}

const UserForm = (props: PropsTypes) => {
  const {
    initialValues,
    history,
    onSubmit,
    mt: memberType,
    permissions = [],
    submitting,
    getUserAccounts,
    mi: memberId,
    unblockClick,
    userAccounts = [],
  } = props;
  const { auth } = useAuthContext();

  const GoBackToPage = useCallback(() => {
    history.push(
      `/${memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'}/accountinfo/useraccount`,
    );
  }, [history, memberType]);

  useEffect(() => {
    if (memberId) getUserAccounts(memberId);
  }, [getUserAccounts, memberId]);

  useSetBreadcrumb({
    component: (
      <UseraccountControl
        cancel={GoBackToPage}
        disable={submitting}
        submit={() => {
          const submitBtn = document.getElementById('addedituserform');
          submitBtn?.click();
        }}
      />
    ),
    page: 'Account Add edit user',
    title: 'Account Info',
    altname: 'Account Info',
    name: 'Account Info',
  });

  const { accountId = '' } = initialValues || {};

  const currentAccount =
    userAccounts.find((item: { accountId: number }) => item.accountId === accountId) || '';
  const onSubmitHandle = (values: any, form: any) => {
    if (values) {
      onSubmit(values);
      form.setSubmitting(false);
    }
  };

  return (
    <div className='container'>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmitHandle}
      >
        {formikProps => {
          const {
            values,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            isValidating,
            validateForm,
            isValid,
            dirty,
          } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='colWrapper'>
                    <div className='row d-flex '>
                      <div className='col-md-5'>
                        <h3 className='arrowWrapper'>
                          {initialValues && initialValues.accountId !== 0 ? 'Edit' : 'Add'} User
                          Account
                        </h3>
                        <h4>User Information</h4>
                        <Field
                          name='firstName'
                          // type='text'
                          component={renderDeprecatedTextField}
                          label='First Name'
                          placeholder='First Name'
                          onChange={setFieldValue}
                          maxLength={25}
                        />
                        <Field
                          name='lastName'
                          // type='text'
                          component={renderDeprecatedTextField}
                          label='Last Name'
                          placeholder='Last Name'
                          onChange={setFieldValue}
                          maxLength={25}
                        />
                        <Field
                          name='username'
                          // type='text'
                          component={renderDeprecatedTextField}
                          label='Username'
                          placeholder='e.g: mail@example.com'
                          onChange={setFieldValue}
                          maxLength={80}
                        />
                        <Field
                          name='email'
                          // type='email'
                          component={renderDeprecatedTextField}
                          label='Email Address'
                          placeholder='Email Address'
                          onChange={setFieldValue}
                          maxLength={100}
                        />
                        <Field
                          name='initials'
                          // type='text'
                          component={renderDeprecatedTextField}
                          label='Initials'
                          placeholder='Initials'
                          onChange={setFieldValue}
                          maxLength={3}
                        />
                        <Field
                          name='jobTitle'
                          // type='text'
                          component={renderDeprecatedTextField}
                          label='Job Title'
                          placeholder='Job Title'
                          optional={true}
                          onChange={setFieldValue}
                          maxLength={60}
                        />
                        <Field
                          name='phoneNumber'
                          // type='text'
                          component={renderDeprecatedTextField}
                          label='Phone Number'
                          placeholder='Phone Number'
                          parentClass='w-48 float-left'
                          optional={true}
                          onChange={setFieldValue}
                          maxLength={17}
                        />
                        <Field
                          name='phoneExtension'
                          // type='text'
                          component={renderDeprecatedTextField}
                          label='Extension'
                          placeholder='Extension'
                          parentClass='w-48 float-right'
                          optional={true}
                          maxLength={10}
                          onChange={setFieldValue}
                        />
                        <span className='clearfix' />
                        {/* for OPS inactive user pinin*/}
                        {auth &&
                        auth?.opsId &&
                        currentAccount &&
                        !currentAccount.active &&
                        currentAccount.exceededBidVisit ? (
                          <>
                            <h4 className='lineHR'>Unblock User</h4>
                            <Buttons
                              type='button'
                              text='Unblock'
                              classNames='bttn-primary'
                              icon='mdi-lock-open-variant-outline'
                              onClick={unblockClick}
                            />
                          </>
                        ) : null}
                        <h4 className='mt-4'>Account Status</h4>
                        {initialValues && initialValues.mainContact ? null : initialValues &&
                          initialValues.active ? (
                          <>
                            {accountId && auth?.mainContact ? (
                              <Field
                                name='mainContact'
                                component={renderToggleBtn}
                                label='Main Contact'
                                placeholder='Main Contact'
                                disabled={accountId === auth?.accountId ? true : false}
                                handleChange={setFieldValue}
                                maxLength={80}
                              />
                            ) : null}
                          </>
                        ) : null}
                        <Field
                          name='notify'
                          component={renderToggleBtn}
                          label='Receives Notifications'
                          placeholder='Receives Notifications'
                          handleChange={setFieldValue}
                        />
                        <h4>Permissions</h4>

                        {permissions && permissions.length > 0
                          ? permissions.map((permission, index) => {
                              return (
                                <Field
                                  name={`permissionCheck.${permission.permissionId}`}
                                  component={renderCheckBox}
                                  title={permission.permissionName}
                                  key={index}
                                  disabled={
                                    accountId === auth?.accountId && !auth?.opsId ? 'true' : 'false'
                                  }
                                  handleChange={setFieldValue}
                                />
                              );
                            })
                          : null}

                        <div className='d-block mt-4'>
                          <Buttons
                            text='Cancel'
                            title='Cancel'
                            classNames='bttn bttn-secondary float-left'
                            onClick={GoBackToPage}
                          />
                          {/* {userinfo ? <Buttons text='Send Password' classNames='bttn bttn-secondary ml-5' /> : null} */}
                          <Buttons
                            text='Save Changes'
                            classNames='bttn bttn-primary float-right'
                            type='submit'
                            id='addedituserform'
                            disable={isSubmitting}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default memo(UserForm);
