import React from 'react';
import { useQuery } from 'react-query';
import { dashboardQuotesList } from '../../store/services/shared';

const GetDashboardQuotesListData = (data: any) => {
  const response = dashboardQuotesList(data);
  return response;
};

export const useGetDashboardQuotesListData = (data: any) => {
  return useQuery(['dashboardQuotes'], () => GetDashboardQuotesListData({}), {
    staleTime: 1800000,
  });
};
