import jwt from 'jsonwebtoken';

import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import Configurations from 'src/settings';

import type { AuthContextType, AuthProviderProps } from '.';
import { Authorization, GetAuthTokenRequest, LegacyPayload } from 'src/types';
import { AuthToken, getTokenData } from 'src/utils/token';
import {
  BROADCAST_CHANNEL_AUTH_ID,
  BROADCAST_CHANNEL_CODE,
  broadcastChannelLogout,
  broadcastChannelReload,
} from 'src/utils/helpers/broadcast-channel';
import { deleteCookie, getCookie, setCookie } from 'src/utils/cookie';
import {
  appstatetokenName,
  limitedloginPreviousUrlName,
  loginPreviousUrlName,
  memberCookieName,
  MemberType,
  Paths,
  relogintokenName,
  storedCommoditiestokenName,
  themetokenName,
  tokenCookieName,
} from 'src/utils/constants';

import Api from 'src/store/services/api';
import { clearTokenCookie, deleteAspCookies } from 'src/utils/helpers';
import { constants } from 'src/utils/settings';
import { getAuthToken } from 'src/service/auth';

export enum AuthUserType {
  Anonymous = 'A',
  DSUser = 'DS',
  Initial = '',
  Ops = 'O',
}

export enum AuthKey {
  AccountCreatedDate = 'acd',
  AccountId = 'us',
  AccountLocked = 'lkd',
  AccountLockedMinutes = 'lm',
  AccountPermissions = 'prms',
  DisabledNotifications = 'dn',
  Email = 'eml',
  FirstName = 'fn',
  FreeMemberId = 'fmi',
  FullName = 'fln',
  HasError = 'her',
  LastLogin = 'll',
  LastName = 'ln',
  MainContact = 'mc',
  MemberBlackListed = 'mbl',
  MemberCreatedDate = 'mcd',
  MemberId = 'mi',
  MembershipLevels = 'ml',
  MemberStatus = 'ms',
  MemberType = 'mt',
  Migrated = 'mgrtd',
  OpsId = 'opi',
  PrimaryMemberId = 'pmid',
  ProductType = 'pt',
  Theme = 'tm',
  Token = 'tk',
  Username = 'un',
  /** @warning this is different from our standard use of userType. Should be typed as AuthUserType */
  AuthUserType = 'ut',
}

export const AuthContext = createContext<AuthContextType>({
  authIsLoading: true,
  token: '',
  handleLogin: async () => {},
  handleLimitedPageLogin: async () => {},
  handleLogout: () => {},
  handleStoredLogin: () => {},
  isTokenExpired: () => false,
  resetAuth: () => {},
  refreshAuth: () => {},
});

export function useAuthContext() {
  return useContext(AuthContext);
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [auth, setAuth] = useState<Authorization>();
  const [authIsLoading, setAuthIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState(false);
  const [token, setToken] = useState<string>('');
  const [memberId, setMemberId] = useState<number>();
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);

  /**
   * @returns: {TokenExpiration} boolean
   * @description: Checks if auth context token is expired and returns a boolean
   */
  const isTokenExpired = useCallback(() => {
    if (token) {
      const decodedToken = jwt.decode(token) as AuthToken;
      // Assert(tokenData, 'We should not get a null value when decoding tokenData', 'helpers/auth.ts');
      if (decodedToken && decodedToken.exp && Date.now() > decodedToken.exp * 1000) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }, [token]);

  /**
   * @returns: {Token} string
   * @description: Fetches newest token from API for current auth context
   */
  const getRefreshToken = useCallback(async () => {
    const data = {};
    const response: any = await Api.postRequestWithAuthentication(Paths.auth.refreshToken, data, {
      baseURL: constants.api.authUrl,
    });

    return response.data.token;
  }, []);

  const resetAuth = () => {
    handleLogout();
  };

  const processToken = useCallback(
    (token: string) => {
      const tokenData = getTokenData(token);
      if (isTokenExpired()) {
        getRefreshToken();
        return;
      }

      // Set local context state
      setToken(token);
      setMemberId(tokenData.memberId);

      // Set cookies to persist auth
      setCookie(memberCookieName, tokenData.memberId, 1);
      setCookie(tokenCookieName, token, 1);
      //  let opsTokendata={...tokenData,opsId:101}
      // To login as OPS in local uncomment above line and replace 'tokenData' with 'opsTokenData' in below line
      setAuth(tokenData);

      // Clear logging out state
      sessionStorage.removeItem('isLoggingOut');
    },
    [getRefreshToken, isTokenExpired],
  );

  /**
   * @param: {GetAuthTokenRequest} payload
   * @description: Fetches token from API and sets auth context for current user
   */
  const handleLogin = async ({ userName, password }: GetAuthTokenRequest) => {
    try {
      setAuthIsLoading(true);
      const response: any = await getAuthToken({ userName, password });

      processToken(response.token);
      auth && setAuthIsLoading(false);
      setCookie(memberCookieName, memberId, 1);
    } catch (error) {
      setHasError(true);
      console.warn(error);
    }
  };

  const handleLimitedPageLogin = async ({
    userName,
    password,
    pagefor,
    orderBidPackage,
    pageBid,
  }: LegacyPayload) => {
    try {
      setAuthIsLoading(true);
      const response: any = await getAuthToken({ userName, password });
      processToken(response.token);
      auth && setAuthIsLoading(false);
      setCookie(memberCookieName, memberId, 1);
      if (!auth?.hasError && pagefor === 'popup' && orderBidPackage === true) {
        sessionStorage.setItem('orderBidPackage', 'checkDownload');
      } else if (pagefor === 'popup' && pageBid) {
        sessionStorage.setItem(limitedloginPreviousUrlName, `/bids/${pageBid}/details`);
      }
    } catch (error) {
      setHasError(true);
      console.warn(error);
    }
  };

  const refreshAuth = async () => {
    try {
      setAuthIsLoading(true);
      const token = await getRefreshToken();
      processToken(token);
      auth && setAuthIsLoading(false);
    } catch (error) {
      setHasError(true);
      console.warn(error);
    }
  };

  /**
   * @returns: {Authorization} tokenData
   * @description: Fetches token from cookie and sets auth context for current user
   */
  const handleStoredLogin = useCallback(() => {
    const storedToken = getCookie(tokenCookieName);

    // User is not logged in but does not have a cookie stored
    // This will allow the login screen to be displayed
    if (!auth && !storedToken) {
      setAuthIsLoading(false);
    }

    if (storedToken && !auth) {
      setAuthIsLoading(true);
      processToken(storedToken);
      auth && setAuthIsLoading(false);
      setCookie(memberCookieName, memberId, 1);
    }
  }, [auth, processToken, memberId]);

  /**
   * @description: Logs out user and resets auth context
   */

  // Broadcast Channel logout handling
  const bcAuth = useMemo(() => new BroadcastChannel(BROADCAST_CHANNEL_AUTH_ID), []);

  const handleLogout = useCallback(() => {
    setToken('');
    clearTokenCookie();
    sessionStorage.setItem('isloggingout', '2');
    deleteCookie(tokenCookieName);
    deleteCookie(memberCookieName);
    deleteAspCookies();
    localStorage.removeItem(appstatetokenName);
    localStorage.removeItem(storedCommoditiestokenName);
    localStorage.removeItem(themetokenName);
    sessionStorage.removeItem(loginPreviousUrlName);
    sessionStorage.removeItem(relogintokenName);
    broadcastChannelReload(bcAuth);
  }, [bcAuth]);

  useEffect(() => {
    handleStoredLogin();

    if (token) {
      setAuthenticated(true);
      setAuthIsLoading(false);
    }
  }, [handleStoredLogin, setAuthenticated, token]);

  useEffect(() => {
    const storedToken = getCookie(tokenCookieName);
    if (auth?.token !== undefined && storedToken !== auth?.token) {
      broadcastChannelReload(bcAuth);
    }
  }, [auth, bcAuth, memberId]);

  /* Value returned to components using context */
  const value = {
    auth,
    authIsLoading,
    hasError,
    isAuthenticated,
    isTokenExpired,
    setHasError,
    token,
    handleLogin,
    handleLimitedPageLogin,
    handleStoredLogin,
    handleLogout,
    resetAuth,
    refreshAuth,
    getRefreshToken,
  };

  useEffect(() => {
    bcAuth.addEventListener('message', (event: MessageEvent) => {
      console.log('AuthProvider.EventListener("message") - event', event);

      // flag login error based upon event data
      if (event?.data === BROADCAST_CHANNEL_CODE.loginError) {
        setHasError(true);
        setAuthIsLoading(false);

        return;
      }
      if (event?.data === BROADCAST_CHANNEL_CODE.reload) {
        window.location.reload();
        return;
      }

      handleLogout();
      window.location.href = Configurations.REACT_APP_REDIRECT_HOME_URL;
    });
    return () => {
      bcAuth.close();
    };
  }, [bcAuth, handleLogout]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const MockAuthProvider = ({ auth, children }: any) => (
  <AuthContext.Provider
    value={
      {
        auth: {
          accountId: 1234567890,
          accountLocked: false,
          accountLockedMinutes: 0,
          accountPermissions: '',
          bidVisitExceededUrl: 'bidVisitExceededUrl',
          email: 'hzdkv@example.com',
          firstName: 'Test',
          fullName: 'Test Test',
          hasError: false,
          isBidVisitExceeded: false,
          lastName: 'Test',
          mainContact: true,
          memberBlackListed: false,
          memberCreatedDate: '2019-01-01T00:00:00',
          memberId: 0,
          membershipLevels: '',
          memberStatus: 'Active',
          memberType: MemberType.AgencyBuyer,
          migrated: false,
          opsId: 0,
          primaryMemberId: 1,
          themetokenName: '',
          token: '',
          userName: '',
          userType: AuthUserType.DSUser,
          validatedResult: { status: true, firstName: '', lastName: '' },
          ...auth,
        },
      } as any
    }
  >
    {children}
  </AuthContext.Provider>
);

export default AuthProvider;
