import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { commonText } from '@demandstar/components/constants';
import { DSTextField } from '@demandstar/components/fields';
import { emailVal } from '@demandstar/components/utils';
import { FlexContainer } from '@demandstar/components/styles';

import { isValidRequiredTrimmingSpace, toastFn } from '../../../../utils/helpers';
import { incompleteRegistrationState } from '../../../../store/recoil/registrationState';
import { limitedloginPreviousUrlName } from 'src/utils/constants';
import { SignUpButton } from 'src/components/common/layout/StaticHeader.styles';
import { useAmplitude } from '../../../amplitude';
import { useRegistration } from 'src/shared/hooks/useRegistration';
import { useRouter } from 'src/shared/hooks';

export function CreateAccountForm() {
  const { history } = useRouter();
  const methods = useForm();
  const { handleSubmit, setValue } = methods;
  const { logEvent, setUserProperties } = useAmplitude();

  const {
    checkAccountExists,
    registration: registrationData,
    resetRegistrationState,
    setRegistration,
  } = useRegistration();
  const { emailAddress = '', companyName = '', acceptTerms = false } = registrationData;

  const {
    routerParams: { memberId },
  } = useRouter();
  const setRecoilRegistrationData = useSetRecoilState(incompleteRegistrationState);

  function handleLoginClick() {
    sessionStorage.setItem(limitedloginPreviousUrlName, '/bids/');
    history.push('/login');
  }

  useEffect(() => {
    if (emailAddress) {
      setValue('emailAddress', emailAddress);
    }

    if (companyName) {
      setValue('companyName', companyName);
    }
  }, [emailAddress, companyName, setValue]);

  useEffect(() => {
    resetRegistrationState();
  }, [resetRegistrationState]);

  function trimInputValue(value?: string) {
    if (value) {
      return value.trim();
    }
    return '';
  }

  const onSubmitFn = (values: { emailAddress: string; companyName: string }) => {
    if (acceptTerms) {
      setRecoilRegistrationData({
        ...registrationData,
        emailAddress: values.emailAddress,
        companyName: values.companyName,
      });
      checkAccountExists({ ...values, memberId });
      setUserProperties({
        Email: values.emailAddress,
        'Organization Name': values.companyName,
      });
      logEvent('registration (supplier) - added email', {
        Description: 'Add email and company name',
      });
    } else toastFn('error', 'Please accept DemandStar Terms of Use and Privacy Policy');
  };

  return (
    <form onSubmit={handleSubmit(onSubmitFn)}>
      <div className='d-flex align-items-end flex-column h-100'>
        <FormProvider {...methods}>          
          <DSTextField
            dataTestId='registration-input-company-name'
            label='Company Name'
            name='companyName'
            rules={{
              maxLength: 150,
              setValueAs: trimInputValue,
              validate: isValidRequiredTrimmingSpace,
            }}
          />
          {/** TODO: Replace this with DSEmailField
           * If trimming input value is essential, we should incorporate that into DSEmail
           */}
          <DSTextField
            dataTestId='registration-input-email'
            label='Email Address'
            message='This will be your username for logging in to DemandStar'
            name='emailAddress'
            rules={{
              setValueAs: trimInputValue,
              pattern: {
                value: emailVal,
                message: 'Invalid email format',
              },
              validate: isValidRequiredTrimmingSpace,
            }}
          />
        </FormProvider>

        <div className='clearfix w-100 text-center'>
          <FlexContainer>
            <SignUpButton
              type='submit'
              title='Create your DemandStar account'
              onClick={() => {
                setRegistration({
                  ...registrationData,
                  acceptTerms: true,
                });
              }}
            >
              Create your DemandStar account
            </SignUpButton>
            <SignUpButton onClick={handleLoginClick} title={commonText.signIn} type='secondary'>
              {commonText.signIn}
            </SignUpButton>
          </FlexContainer>
          <small>
            {"By creating an account, you agree to DemandStar's "}
            {/**TODO: Replace below with constants. */}
            <a href='https://network.demandstar.com/terms-of-use/'>Terms of Use</a> and{' '}
            <a href='https://network.demandstar.com/privacy-policy/'>Privacy Policy</a>.
          </small>
        </div>
      </div>
    </form>
  );
}
