import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { memo, useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import * as actionCreators from '../../../store/actions';
import { awardeeStatus, BidExternalStatusType } from '../../../utils/constants';
import {
  bidItemParamType,
  bidssummaryParamType,
  purchaseInfoParamType,
} from '../../../types/biddetail';
import { addbidParamType } from '../../../types/addbid';
import { BidAwardeesView } from './tabs/bid-awardees';
import { BidDetailsControl } from '../../common/breadcrumb/buyer/BidDetailsControl';
import { BidInfo } from '../../shared/bidsdetail/lefttab/bidinfo';
import { bidsSummary } from '../../../store/actions';
import { initialStateBidsSummary } from '../../../store/reducers/bidssummary';
import { memberinfoParamType } from '../../../types/bids';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { nargsfnTypes } from '../../../types/functions';
import { QuoteSummaryType } from '../../../types/quotedetailstypedef';
import { sharedTypes } from '../../../types/shared';
import { useAuthContext } from 'src/auth/AuthProvider';
import { useForceUpdate } from '../../../utils/helperHooks';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { useSetSelectedBidIdFromRoute } from '../../../shared/hooks/useSelectedBidId';

import AdditionalBroadcast from './additionalbroadcast';
import Audittrail from './tabs/audittrail';
import Broadcast from '../../common/createbid/modals/broadcast';
import BroadcastHistory from './tabs/broadcasthistory';
import EbidResponse from './tabs/ebidresponse';
import navHistory from '../../../utils/history';
import Planholders from './tabs/planholders';
import Postviewers from './tabs/planholders/postviewers';
import Tabulation from './tabs/tabulation/index';
import Watchers from './tabs/watchers';

const bidDetailTabIds = {
  audittrial: 'Audit Trail',
  awardees: 'Awardee Management',
  broadcast: 'Broadcast History',
  details: 'Bid Details',
  ebidresponse: 'eBid Responses',
  planholders: 'Planholders',
  postviewers: 'Postbid Viewers',
  reminders: 'Reminders',
  tabulation: 'Tabulation Sheet',
  watchers: 'Watchers List',
};

type TabId = keyof typeof bidDetailTabIds;

interface propTypes extends PropsFromRedux {
  bidssummary: bidssummaryParamType;
  addbid: addbidParamType;
  shared: sharedTypes;
  purchaseInfo: purchaseInfoParamType;
  form: any;
  memberinfo: memberinfoParamType;
  match: { params: { bidId: string; Tab: string } };
  orderBidPackage: any;
  data: bidItemParamType;
  forceUpdateAfterTimerCompletion: () => void;
  reloadPageDetails: () => void;
  bidWriterInfo: Record<string, unknown>;
  bidsSummary: any;
  getWriterInfo: any;
  loadBidsAuditTrial: any;
  setAddBidDetails: any;
  setBidSummaryDetails: any;
  getAddBidCanComplete: any;
  getAwardees: any;
  bidsWatchers: any;
  getBidView: any;
  setSharedDetails: nargsfnTypes;
  getSelfDeclarations: any;
  exportBroadcastList: any;
  exportSupplementalSuppliers: any;
  bidsBidWatch: any;
  downloadAllDocument: any;
  quoteSummary: QuoteSummaryType;
}

const Bidresponse = (props: propTypes) => {
  // Set the recoil `selectedBidId` state from the route bidId after the page loads.
  useSetSelectedBidIdFromRoute();

  const dispatch = useDispatch();
  const bidID = parseInt(props.match.params.bidId);
  const Tab: TabId = props.match.params.Tab as TabId;

  const [activeTab, setActiveTab] = useState<TabId>(Tab || 'details');

  const {
    bidssummary,
    loadBidsAuditTrial,
    setAddBidDetails,
    setBidSummaryDetails,
    addbid,
    getAddBidCanComplete,
    getAwardees,
    bidsWatchers,
    getBidView,
    setSharedDetails,
    setEbidDetails,
  } = props;
  const { auth } = useAuthContext();
  const { broadcastModal = false, additionalBroadcastModal = false } = addbid;
  const { results, auditTrialCategories } = bidssummary;
  const [reloadSummary, setReloadSummary] = useState(0);

  const forceUpdate = useForceUpdate();
  const forceUpdateAfterTimerCompletion = () => {
    forceUpdate();
  };

  const reloadPageDetails = () => {
    setReloadSummary(1);
    forceUpdateAfterTimerCompletion();
  };

  const {
    bidExternalStatusType = BidExternalStatusType.None,
    dueDate = '',
    eBidding = '',
    memberID = '',
    showPlanholders = '',
    bidID: bidId = '',
    tse: tabSheetOption,
  } = (results as bidItemParamType) || {};

  /**
   * Handles explicit tabId values from each nav link click.
   * @description the native HTML event is ignored.
   * @todo standardize nav tab types and functionality into a component/hook.
   * @param tab
   * @returns
   */
  const selectTab = (tab: TabId) => () => {
    if (activeTab !== tab) {
      navHistory.push(`/buyers/bids/${bidID}/${tab}`);
    }
  };

  useEffect(() => {
    setActiveTab(Tab ? Tab : 'details');
  }, [Tab]);

  const setBroadcastModal = () => {
    setAddBidDetails({ broadcastModal: !broadcastModal });
  };

  const { bidName = '' } = results || '';

  useSetBreadcrumb({
    component: <BidDetailsControl />,
    page: 'Buyer Bids Details',
    altname: bidName,
    title: bidName,
    name: bidName,
    menuactive: 'bids',
  });

  useEffect(() => {
    if (bidID) {
      dispatch(bidsSummary({ auth, bidId: bidID, otherapis: true }));
    }
  }, [auth, bidID, dispatch, reloadSummary]);

  useEffect(() => {
    return () => {
      setBidSummaryDetails({
        results: {},
        currentpostviewer: '',
        PostbidViewers: { result: [] },
        similarSuppliers: [],
        currentsupplier: '',
        planHolders: [],
        selectedSuppliers: [],
        generateTags: {},
      });
      setEbidDetails({
        ebidResponse: [],
      });
    };
  }, [setBidSummaryDetails, setEbidDetails]);

  useEffect(() => {
    if (bidId) getAddBidCanComplete({ bidId });
  }, [getAddBidCanComplete, bidId]);

  useEffect(() => {
    if (bidId && awardeeStatus.includes(bidExternalStatusType)) {
      getAwardees({ bidId });
    }
  }, [bidExternalStatusType, bidId, getAwardees]);

  useEffect(() => {
    let getBidViewAPICALL = true;
    if (getBidViewAPICALL && bidId) {
      getBidView({ bidId });
    }
    return () => {
      getBidViewAPICALL = false;
    };
  }, [bidExternalStatusType, bidId, getBidView]);

  useEffect(() => {
    if (Number(bidId) === bidID) {
      loadBidsAuditTrial({ id: bidID, type: 'Bid' });
      auditTrialCategories.map((item, index) => (auditTrialCategories[index].selected = false));
      auditTrialCategories[0].selected = true;
    }
  }, [auditTrialCategories, bidID, bidId, loadBidsAuditTrial, setSharedDetails]);

  /* useEffect(() => {
    if (bidWriterAccountID && memberID && Number(bidId) === bidID) {
      setSharedDetails({ internalLoader: true });
      getWriterInfo({ writerAccountID: bidWriterAccountID, memberID: memberID });
    }
  }, [getWriterInfo, bidWriterAccountID, memberID, bidId, bidID, setSharedDetails]); */

  useEffect(() => {
    if (Number(bidId) === bidID && Number(memberID) === Number(auth?.memberId)) {
      bidsWatchers({ bidId: bidID });
    }
  }, [setSharedDetails, bidID, bidId, bidsWatchers, memberID, auth?.memberId]);

  useEffect(() => {
    return () => {
      setBidSummaryDetails({ results: {} });
    };
  }, [setBidSummaryDetails]);

  useEffect(() => {
    return () => {
      setBidSummaryDetails(initialStateBidsSummary);
    };
  }, [setBidSummaryDetails]);

  return (
    <>
      <div className='container'>
        <div className='row detailViewWrapper'>
          <div className='col'>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'details' })}
                  onClick={selectTab('details')}
                >
                  Bid Details
                </NavLink>
              </NavItem>
              {memberID === auth?.memberId &&
                (bidExternalStatusType === BidExternalStatusType.Awarded ||
                  bidExternalStatusType === BidExternalStatusType.RecommendationOfAward) && (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'awardees' })}
                      onClick={selectTab('awardees')}
                    >
                      {bidDetailTabIds.awardees}
                    </NavLink>
                  </NavItem>
                )}
              {memberID === auth?.memberId &&
                bidExternalStatusType !== BidExternalStatusType.Upcoming && (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'audittrial' })}
                      onClick={selectTab('audittrial')}
                    >
                      {bidDetailTabIds.audittrial}
                    </NavLink>
                  </NavItem>
                )}
              {memberID === auth?.memberId &&
                bidExternalStatusType !== BidExternalStatusType.Upcoming &&
                showPlanholders && (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'watchers' })}
                      onClick={selectTab('watchers')}
                    >
                      {bidDetailTabIds.watchers}
                    </NavLink>
                  </NavItem>
                )}

              {bidExternalStatusType !== BidExternalStatusType.Upcoming && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'planholders' })}
                    onClick={selectTab('planholders')}
                  >
                    {bidDetailTabIds.planholders}
                  </NavLink>
                </NavItem>
              )}
              {memberID === auth?.memberId &&
                bidExternalStatusType !== BidExternalStatusType.Upcoming && (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'postviewers' })}
                      onClick={selectTab('postviewers')}
                    >
                      {bidDetailTabIds.postviewers}
                    </NavLink>
                  </NavItem>
                )}
              {memberID === auth?.memberId &&
                bidExternalStatusType !== BidExternalStatusType.Upcoming &&
                eBidding !== 'No' && (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'ebidresponse' })}
                      onClick={selectTab('ebidresponse')}
                    >
                      {bidDetailTabIds.ebidresponse}
                    </NavLink>
                  </NavItem>
                )}
              {bidExternalStatusType !== BidExternalStatusType.Upcoming && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'broadcast' })}
                    onClick={selectTab('broadcast')}
                  >
                    {bidDetailTabIds.broadcast}
                  </NavLink>
                </NavItem>
              )}
              {/* {memberID === auth?.memberId && bidExternalStatusType !== BidExternalStatusType.Upcoming && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'reminders' })}
                    onClick={selectTab('reminders')}
                  >
                    {bidDetailTabIds.reminders}
                  </NavLink>
                </NavItem>
              )} */}

              {memberID === auth?.memberId &&
                bidExternalStatusType !== BidExternalStatusType.Upcoming &&
                eBidding !== 'No' &&
                !(
                  Number(tabSheetOption) === 0 &&
                  bidExternalStatusType === BidExternalStatusType.Active
                ) && (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'tabulation' })}
                      onClick={selectTab('tabulation')}
                    >
                      {bidDetailTabIds.tabulation}
                    </NavLink>
                  </NavItem>
                )}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId='details'>
                <div className='row justify-content-center'>
                  <div className='col-lg-12'>
                    {results && (
                      <BidInfo
                        {...props}
                        data={results as bidItemParamType}
                        reloadPageDetails={reloadPageDetails}
                        forceUpdateAfterTimerCompletion={forceUpdateAfterTimerCompletion}
                      />
                    )}
                  </div>
                </div>
              </TabPane>
              {bidExternalStatusType !== BidExternalStatusType.Upcoming ? (
                <>
                  <TabPane tabId='audittrial'>
                    {activeTab === 'audittrial' && <Audittrail {...props} bidID={bidID} />}
                  </TabPane>
                  <TabPane tabId='watchers'>
                    {activeTab === 'watchers' && <Watchers {...props} />}
                  </TabPane>
                  <TabPane tabId='planholders'>
                    {activeTab === 'planholders' && <Planholders {...props} />}
                  </TabPane>
                  <TabPane tabId='postviewers'>
                    {activeTab === 'postviewers' && <Postviewers {...props} />}
                  </TabPane>
                  <TabPane tabId='awardees'>
                    {activeTab === 'awardees' && <BidAwardeesView />}
                  </TabPane>
                  {eBidding !== 'No' && (
                    <TabPane tabId='ebidresponse'>
                      {activeTab === 'ebidresponse' && (
                        <EbidResponse {...props} dueDate={dueDate} />
                      )}
                    </TabPane>
                  )}
                  <TabPane tabId='broadcast'>
                    {activeTab === 'broadcast' && <BroadcastHistory {...props} bidID={bidID} />}
                  </TabPane>
                  {/* <TabPane tabId='reminders'>
                    {activeTab === 'reminders' && <Bidreminders {...props} bidID={bidID} />}
                  </TabPane> */}
                  {eBidding !== 'No' &&
                    !(
                      Number(tabSheetOption) === 0 &&
                      bidExternalStatusType === BidExternalStatusType.Active
                    ) && (
                      <TabPane tabId='tabulation'>
                        {activeTab === 'tabulation' && <Tabulation {...props} />}
                      </TabPane>
                    )}
                </>
              ) : null}
            </TabContent>
          </div>
        </div>
      </div>
      <ModalPopUp
        title='Broadcast'
        closeModal={setBroadcastModal}
        isOpen={broadcastModal}
        backdrop='static'
        size='lg'
      >
        <Broadcast {...props} setBroadcastModal={setBroadcastModal} />
      </ModalPopUp>

      <ModalPopUp
        title='Add Suppliers to Broadcast'
        closeModal={() => setAddBidDetails({ additionalBroadcastModal: !additionalBroadcastModal })}
        isOpen={additionalBroadcastModal}
        backdrop='static'
        size='xl'
      >
        <AdditionalBroadcast {...props} />
      </ModalPopUp>
    </>
  );
};

const connector = connect(
  (state: any) => ({
    bidssummary: state.bidssummary,
    shared: state.shared,
    ebids: state.ebids,
    addbid: state.addbid,
    quoteSummary: state.quoteSummary,
    memberinfo: state.memberinfo,
    commoditycodes: state.commoditycodes,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(Bidresponse));
