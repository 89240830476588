import { connect, ConnectedProps } from 'react-redux';
import { memo, useEffect, useState } from 'react';

import { bindActionCreators } from 'redux';

import * as actionCreators from '../../../store/actions';

import { Buttons } from '../../customcontrols';
import { DeprecatedInput } from '../../customcontrols/input';
import { limitedbiddetailsTypes } from '../../../types/limited';
import { LimitedFooter } from '../footer';
import { LimitedHeader } from '../header';
import { MemberType } from '../../../utils/constants';
import { toastFn } from '../../../utils/helpers';
import { useAuthContext } from 'src/auth/AuthProvider';
import PasswordStrengthBar from 'react-password-strength-bar';

const minCharError = 'The minimum password length is 8 characters. Please choose another password';
const maxCharError = 'Passwords cannot be more than 20 characters.';
const patternError =
  'The minimum password length is 8 characters, and must include at least one letter and number. Please choose another password.';

interface propsTypes extends PropsFromRedux {
  limited: limitedbiddetailsTypes;
  history: any;
  match: any;
  setLimitedDetails: any;
  submitLimitedResetPassword: any;
  getLimitedVerifySecurityCode: any;
}

function ResetPassword(props: propsTypes) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmpassword] = useState('');
  const [passworderror, setPassworderror] = useState('');
  const [confirmpassworderror, setConfirmpassworderror] = useState('');
  const {
    submitLimitedResetPassword,
    setLimitedDetails,
    limited,
    match,
    history,
    getLimitedVerifySecurityCode,
  } = props;
  const { auth } = useAuthContext();
  const { passwordreset, securitycode } = limited;
  const { reason, token = '' } = passwordreset;
  const code = match.params.ID || '';

  useEffect(() => {
    if (code) {
      getLimitedVerifySecurityCode({ code });
    }
  }, [code, getLimitedVerifySecurityCode]);

  useEffect(() => {
    if (!code) {
      history.replace('/');
    }
  }, [code, history]);

  const SubmitResetPaswordFn = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\S]{8,}$/;
    const passMinLength = password.length > 7 && confirmPassword.length > 7;
    const passMaxLength = password.length <= 20 && confirmPassword.length <= 20;
    const match = password === confirmPassword;
    if (password && confirmPassword && passMinLength && passMaxLength && match) {
      if (!passwordPattern.test(password)) {
        setPassworderror(patternError);
      } else if (password.match('<[Ss][Cc][Rr][Ii][Pp][Tt]')) {
        setPassworderror('Password cannot have <script in it');
      } else {
        if (code && securitycode)
          submitLimitedResetPassword({
            Password: password,
            UserName: securitycode,
            Otp: code,
            OpsId: auth?.opsId ? auth?.opsId : 0,
          });
      }
    } else {
      if (!password) setPassworderror('Required');
      if (!confirmPassword) setConfirmpassworderror('Required');
      if (password && confirmPassword) {
        if (password.length < 8) setPassworderror(minCharError);
        if (confirmPassword.length < 8) setConfirmpassworderror(minCharError);
        if (password.length > 20) setPassworderror(maxCharError);
        if (confirmPassword.length > 20) setConfirmpassworderror(maxCharError);
        if (passMinLength && password !== confirmPassword) {
          setConfirmpassworderror('The password and confirm Password does not match');
        }
      }
    }
  };

  useEffect(() => {
    if (reason === 'success' && token) {
      if (!auth?.opsId) {
        setTimeout(() => {
          history.replace('/loginbytoken');
        }, 4000);
      } else {
        if (reason === 'success') toastFn('success', 'Password has been updated');
        else toastFn('error', 'Nettwork error. Please try again later.');
        history.goBack();
      }
    }
  }, [auth, history, reason, token]);

  useEffect(() => {
    return () => {
      setLimitedDetails({ passwordreset: { reason: '', token: '' }, securitycode: '' });
    };
  }, [setLimitedDetails]);

  return (
    <>
      <LimitedHeader pagefor='accountrecovery' />
      <div className='unAuthWrapper'>
        <div className='container pt-5'>
          <div className='row d-flex justify-content-center'>
            <div className='col-12 col-md-11'>
              <div className='colWrapper'>
                <div className='row'>
                  <div className='col-12 col-md-7'>
                    <h2 className='arrowWrapper'>Create a New Password</h2>
                    {securitycode && securitycode.length ? (
                      <>
                        {reason && !auth?.opsId ? (
                          <>
                            {reason === 'success' ? (
                              <>
                                {token ? (
                                  <>
                                    <p>Your password has been reset.</p>
                                    <p>
                                      You will now be logged in and redirected to your Dashboard.
                                    </p>
                                    {auth?.opsId ? (
                                      <div className='d-block mt-3'>
                                        <Buttons
                                          text='Back'
                                          classNames='bttn bttn-secondary float-left'
                                          onClick={() =>
                                            history.push(
                                              `/${
                                                auth?.memberType === MemberType.AgencyBuyer
                                                  ? 'buyers'
                                                  : 'suppliers'
                                              }/accountinfo/useraccount`,
                                            )
                                          }
                                        />
                                      </div>
                                    ) : null}
                                  </>
                                ) : null}
                                {!token ? <p>Nettwork error. Please try again later.</p> : null}
                              </>
                            ) : (
                              <p>Network error. Please try again later.</p>
                            )}
                          </>
                        ) : (
                          <form>
                            {/*<h2 className='arrowWrapper'>Create a New Password</h2>*/}
                            <DeprecatedInput
                              name='password'
                              type='password'
                              label='Password'
                              placeholder='Password'
                              handleChange={(name: string, value: string) => {
                                setPassworderror('');
                                setPassword(value ? value.trim() : value);
                              }}
                              meta={{ touched: true, error: passworderror }}
                              maxLength={40}
                            />
                            <PasswordStrengthBar password={password} minLength={8} />
                            <DeprecatedInput
                              name='confirmPassword'
                              type='password'
                              label='Confirm Password'
                              placeholder='Confirm Password'
                              handleChange={(name: string, value: string) => {
                                setConfirmpassworderror('');
                                setConfirmpassword(value ? value.trim() : value);
                              }}
                              meta={{ touched: true, error: confirmpassworderror }}
                              maxLength={40}
                            />
                            <Buttons
                              type='submit'
                              classNames='bttn-primary float-right mt-4'
                              text='Create Password'
                              title='Create Password'
                              onClick={SubmitResetPaswordFn}
                            />
                          </form>
                        )}
                      </>
                    ) : (
                      <p>Invalid / expired security code</p>
                    )}
                  </div>
                  {securitycode && securitycode.length && reason !== 'success' && (
                    <div className='col-12 col-md-5 mt-5'>
                      <h4>Password Rules </h4>
                      <ul>
                        <small>
                          <li>Minimum length: 8 characters</li>
                        </small>
                        <small>
                          <li>
                            {' '}
                            Don't use simple sequences (e.g., 12345) or repeated characters (e.g.,
                            AAAA).
                          </li>
                        </small>
                        <small>
                          {' '}
                          <li>
                            {' '}
                            Include a mix of uppercase and lowercase letters, numbers, and special
                            characters (e.g., !, @, #, $, %)
                          </li>
                        </small>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LimitedFooter />
    </>
  );
}
const connector = connect(
  (state: any) => ({
    limited: state.limited,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(ResetPassword));
