import React from 'react';
import { useQuery } from 'react-query';
import { dashboardBidsList } from '../../store/services/shared';

const GetDashboardBidsListData = (data: any) => {
  const response = dashboardBidsList(data);
  return response;
};

export const useGetDashboardBidsListData = (data: any) => {
  return useQuery(['dashboardBids'], () => GetDashboardBidsListData({}), { staleTime: 1800000 });
};
