import React from 'react';
import { useQuery } from 'react-query';
import { getdashboarddata } from '../../store/services/shared';

const GetDashboardData = (data: any) => {
  const response = getdashboarddata(data);
  return response;
};

export const useGetDashboardData = (data: any) => {
  return useQuery(['dashboardData'], () => GetDashboardData({}), { staleTime: 1800000 });
};
