import { useQuery } from 'react-query';
import { getDashboardNetwork } from '../../store/services/shared';

const GetDashboardNetworkData = (data: any) => {
  const response = getDashboardNetwork(data);
  return response;
};

export const useGetDashboardNetworkData = (data: any) => {
  return useQuery(['dashboardNetworkData'], () => GetDashboardNetworkData({}), {
    staleTime: 1800000,
  });
};
