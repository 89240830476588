import { useQuery } from 'react-query';
import { getdashboarddataTracking } from '../../store/services/shared';

const GetDashboardTrackingData = (data: any) => {
  const response = getdashboarddataTracking(data);
  return response;
};

export const useGetDashboardTrackingData = (data: any) => {
  return useQuery(['dashboardTrackingData'], () => GetDashboardTrackingData({}), {
    staleTime: 1800000,
  });
};
