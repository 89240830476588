import React from 'react';
import { useQuery } from 'react-query';
import { getEunaLinks } from '../../store/services/shared';

const GetEunaLinks = (data: any) => {
  const response = getEunaLinks(data);
  return response;
};

export const useGetEunaLinks = (data: any) => {
  return useQuery(['EunaLinks'], () => GetEunaLinks({}), {
    staleTime: 86400000,
    cacheTime: 86400000 /*24HRS*/,
  });
};
